<template>
  <div class="shm">
    <div class="top_bg">
      <div class="main">
        <nav class="hero-nav">
          <a class="hero-home" href="#">
            <!-- <img src="" class="logoimg" alt=""> -->
            滨南时代
          </a>
          <ul class="hero-links">
            <li class="hero-link-item" id="download-button"><a class="hero-link"
                href="https://static.binnanera.com/arg/server-agreement.html">用户协议</a>
            </li>
            <li class="hero-link-item" id="download-button"><a class="hero-link"
                href="https://static.binnanera.com/arg/privacy-agreement.html">隐私协议</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="top_main" style="overflow: hidden;">
      <div class="main">
        <div class="sp_bf">
          <div class="jt_img"></div>
        </div>
        <div class="xz_js">
          <h1>滨南时代</h1>
          <div class="wz_js">
            滨南时代管理平台是实体商户的充电经营管理工具，致力于为客户提供创新的充电服务和营销解决方案。滨南时代提供提供充电、扫码、找桩、营销活动等核心功能。用户可通过客户端注册并登录账号，在首页和地图页可以查找近距离以及带有需要接入的站桩。扫描桩上二维码后启动充电，充电过程中展示充电数据及订单信息，充电结束后可根据桩、平台计算金额结算，并可在个人页进行发票开具。
          </div>
        </div>
      </div>
    </div>
    <div class="main features">
      <div class="feature">
        <h2 class="feature-title feature-swipe">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-kejipeixun"></use>
          </svg>扫码充电
        </h2>
        <p class="feature-desc">充电只需扫一扫，提高落地可操作性</p>
      </div>
      <div class="feature">
        <h2 class="feature-title feature-love"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-keji"></use>
          </svg>操作便捷</h2>
        <p class="feature-desc">根据地区查询站电桩，方便快捷，易用性强</p>
      </div>
      <div class="feature">
        <h2 class="feature-title feature-profile"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-keji1"></use>
          </svg>智能支付</h2>
        <p class="feature-desc">主流支付方式，随时随地掌握收支明细，清晰方便</p>
      </div>
    </div>
    <!-- <div id="download">
      <h2 class="download-title">联系我们</h2>
      <h3 class="download-tel">咨询电话：0730-8752062/0730-8752063</h3>
    </div> -->
    <footer class="footer">
      <div class="footer-row">
        <div>
          <span class="footer-cp footer-component">滨南时代 </span>
          <a class="footer-icp footer-component" href="http://www.beian.gov.cn/portal/registerSystemInfo"
            rel="external nofollow" target="_blank">重庆滨南时代新能源科技有限公司
            &nbsp;&nbsp;渝ICP备2023010341号-2 </a>
          <a class="footer-support footer-component">重庆市两江新区康美街道卉竹路2号6幢22层1号附23-06-0115</a>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
};
</script>

<style>
/* #app { */
/* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
/* overflow-x: hidden;
  font-family: Helvetica Neue, Roboto Light, PingFang SC, Lantinghei SC, Hiragino Sans GB, Microsoft Yahei, Arial;
  font-size: 62.5%;
        min-width: 1120px;
        font-weight: 200 */
/* } */
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

body,
div,
p,
a,
ul,
li,
img {
  margin: 0;
  padding: 0
}

div img {
  vertical-align: bottom
}

.main {
  width: 960px;
  margin: 0 auto
}

.main::after {
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.hero-nav {
  padding-left: 20px;
  line-height: 100px;
}

.hero-home {
  height: 68px;
  width: 162px;
  font-size: 36px;
  color: white;
  text-decoration: none;
  background-position: -435px 0;
  display: inline-block;
  background-size: 599px 540px;
}

.hero-home-en,
.hero-home-ja {
  height: 68px;
  width: 160px;
  background-position: -439px 0;
  vertical-align: middle;
  display: inline-block;
}

.hero-home-ko {
  height: 68px;
  width: 160px;
  background-size: 100% 100%;
  vertical-align: middle;
  display: inline-block;
}

.hero-home-shm {
  height: 68px;
  width: 160px;
  background-position: -439px 0;
  vertical-align: middle;
  display: inline-block;
}

.hero-links {
  /* display: inline-block; */
  float: right;
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: pointer;
  max-height: 100px;
}

.hero-link-item {
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.hero-link,
.hero-link:hover,
.hero-link:link,
.hero-link:visited {
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 1.6em;
}

.hero-link {
  display: block;
}

.hero-link-item {
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.top_main {
  width: 100%;
  background-color: #00a8cf;
  zoom: 1;
}

.top_bg {
  background-color: rgba(0, 0, 0, .8);
  height: 100px;
  line-height: 120px;
}

.footer {
  background-color: rgba(0, 0, 0, .8) !important;
}

.sp_bf {
  float: left;
  padding-top: 20px;
  position: relative;
}


.jt_z {
  height: 53px;
  left: -75px;
  top: 157px;
  background-position: -439px -88px;
}

.jt_y {
  height: 34px;
  right: 6px;
  top: 143px;
  background-position: -439px -163px;
}

.jt_img {
  width: 419px;
  height: 520px;
  background: url(./assets/app-icon.png) no-repeat;
  background-size: cover;
  background-position: 0 30px;
  background-size: 321px 321px;
}

.hero-video {
  position: absolute;
  top: 80px;
  left: 73px;
  border: 1px solid #ddd;
}

.xz_js {
  float: left;
  width: 470px;
  padding: 90px 0 0 60px
}

h1 {
  font-weight: 100;
  font-size: 45px;
  line-height: 55px;
  color: #fff;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

.wz_js {
  font-weight: 200;
  font-size: 18px;
  line-height: 25.2px;
  margin: 30px 0;
  text-align: justify;
  color: #fff
}



.features {
  padding-top: 30px;
}

.feature {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  height: 200px;
  overflow: hidden;
  zoom: 1;
  margin-bottom: 75px;
  float: left;
  margin-right: 3%;
}

.feature-title {
  font-size: 30px;
  color: #303435;
  font-weight: 100;
  padding: 0 30px;
}

h2 {
  display: block;
  font-size: 1.5em;
  -webkit-margin-before: 0.83em;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
}

.feature-desc {
  font-size: 16px;
  color: #818c92;
  margin-top: 30px;
  padding: 0 30px;
  line-height: 22.4px;
}

user agent stylesheet p {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

.feature-swipe span {
  background-position: -72px -62px;
  width: 52px;
  height: 52px;
}

.feature-title span {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 35px;
}

.icon {
  margin-right: .933333rem;
}

.downicon {
  font-size: 25px;
}

.feature-love span {
  background-position: 0 0;
  height: 52px;
  width: 72px
}

.feature-profile span {
  background-position: -92px 0;
  height: 52px;
  width: 52px
}

.feature-security span {
  background-position: 0 -72px;
  height: 52px;
  width: 52px
}

#download {
  clear: both;
  background-color: rgb(223, 221, 212, 0.1);
  padding-bottom: 44px;
  text-align: center;
  overflow: hidden;
}

.download-title {
  color: #00041a;
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  margin: 110px auto 60px;
}

.download-tel {
  font-size: 20px;
  color: rgba(0, 4, 26, .5);
  letter-spacing: 6px;
  text-align: center;
  line-height: 32px;
}

.download-targets {
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
}

.download-target {
  width: 245px;
  height: 245px;
  line-height: 245px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  border: solid 1px #dfddd4;
}

.download-target img {
  vertical-align: middle;
}

.download-target img.hover {
  display: none;
}

.download-target.ios:hover,
.download-target.android:hover {
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
  background-color: #d84d37;
}

.download-target.ios:hover .download-iphone.normal {
  display: none;
}

.download-target.ios:hover .download-iphone.hover {
  display: inline-block;
}

.download-target.android:hover .download-android.normal {
  display: none;
}

.download-target.android:hover .download-android.hover {
  display: inline-block;
}

.download-link {
  display: block;
}

.footer {
  background: #fff;
  text-align: center;
  font-size: 12px;
  padding: 20px 0
}

.footer-row {
  padding: 4px;
  max-width: 975px;
  margin: 0 auto;
}

.footer a {
  text-decoration: none;
  color: #818c92;
  cursor: pointer;
}

.footer-about {
  border-right: 1px solid #818c92;
}

.footer-about {
  padding-right: 10px;
}

.footer-contact {
  padding: 0 10px;
}

#footer-language {
  padding-left: 10px;
}

.footer-component {
  display: inline-block;
  margin: 0 10px;
}

.footer-component {
  display: inline-block;
  margin: 0 10px;
  color: #818c92;
}

.footer-component.footer-grey {
  color: #818c92;
}


.back,
.languages {
  position: fixed;
  right: 0;
  display: none;
}

.back {
  top: 0;
  left: 0;
  bottom: 0;
  content: '';
  background: hsla(0, 0%, 100%, .5);
}

i,
cite,
em,
var,
address,
dfn {
  font-style: italic;
}

.shm {
  overflow-x: hidden;
  font-family: Helvetica Neue, Roboto Light, PingFang SC, Lantinghei SC, Hiragino Sans GB, Microsoft Yahei, Arial;
  font-size: 62.5%;
  min-width: 1120px;
  font-weight: 200;
  background: #ffffff;
  -webkit-box-shadow: 1px 1px 5px #b2b2b2;
  box-shadow: 1px 1px 5px #b2b2b2;
  z-index: 999999
}
</style>
